* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* TODO define font base?????? */
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
}

.outlet {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #fff !important;
    zIndex: -1 !important;
    margin-top: 80px;
    height: calc(100vh - 80px);
    background-color: white;
}

@media (min-width: 1009px) {
    .outlet {
        margin-left: 250px;
    }
}


.rdt_Table {
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px
}

.rdt_TableHeadRow {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: rgba(120, 115, 137, 1);
}

.rdt_TableRow {
    border-bottom-width: 0 !important;
    background-color: transparent !important;
}

.rdt_TableRow:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.rdt_Pagination {
    border-top-width: 0 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
    margin-top: -3px;
}