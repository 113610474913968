@font-face {
    font-family: "Avenir-Regular";
    src: url("./fonts/Avenir/Avenir-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Avenir-Light";
    src: url("./fonts/Avenir/Avenir-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-Black";
    src: url("./fonts/Avenir/Avenir-Black.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

